"use strict";

import { downloadCsvForSepaFile as getCsvForSepaFile, searchCsvForSepa } from "../../api/settled.js";
import { createModal, showLoadingButton, updateButtonLabel } from "../shared/shared.js";

let CSV_FILES_FROM_REQUEST = [];
const ITTEMS_PER_BATCH = 10;

async function renderMoreFiles(containerId) {
  try {
    const response = await searchCsvForSepa(ITTEMS_PER_BATCH, CSV_FILES_FROM_REQUEST.length);
    const tableBody = document.querySelector(`#${containerId} tbody`);

    const createTableRow = (file) => {
      const buttonId = `btn-download-${file.fileName.replace(/\W+/g, "-")}`;

      return `<tr>
        <td>${file.fileName}</td>
        <td>${new Date(file.lastModified).toISOString().slice(0, 19).replace('T', ' ')}</td>
        <td>
          <button id="${buttonId}" class="btn btn-barymont-red w-100">
            <i class="fas fa-download"></i>
          </button>
        </td>
      </tr>`;
    };

    if (response.length === 0 && tableBody.rows.length === 0) {
      tableBody.innerHTML = `<tr><td colspan="3" class="text-center">Todavía no hay ficheros disponibles.</td></tr>`;
      return;
    }

    const newRows = response.map(createTableRow).join("");
    tableBody.insertAdjacentHTML("beforeend", newRows);

    response.forEach((file) => {
      const buttonId = `btn-download-${file.fileName.replace(/\W+/g, "-")}`;
      const button = document.getElementById(buttonId);
      if (button) {
        button.addEventListener("click", async () => {
          await downloadCsvFile(file, buttonId);
        });
      }
    });

    CSV_FILES_FROM_REQUEST = [...CSV_FILES_FROM_REQUEST, ...response];

    const loadMoreButton = document.getElementById("btn-load-more-csv");

    if (response.length === 0 || response.length < ITTEMS_PER_BATCH) {
      loadMoreButton.classList.add("d-none");
      return;
    } else {
      loadMoreButton.classList.remove("d-none");
      updateButtonLabel("btn-load-more-csv", "Cargar más ficheros", "btn-barymont-black");
    }
  } catch (error) {
    console.error("Error al cargar más ficheros:", error);
    createToast("error", "Error al cargar", "No se pudieron cargar más archivos.", 5000);
  }
}

async function downloadCsvFile(file, buttonId) {
  try {
    showLoadingButton(buttonId, "btn-barymont-red", true);

    const response = await getCsvForSepaFile(file.fileName);

    if (!response || !response.body || !response.contentType) {
      throw new Error("La respuesta del servidor no es válida.");
    }

    const blob = new Blob([response.body], { type: response.contentType });

    const downloadUrl = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = file.fileName;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    a.remove();

    URL.revokeObjectURL(downloadUrl);

    updateButtonLabel(buttonId, "<i class='fas fa-download'></i>", "btn-barymont-red");
  } catch (error) {
    console.error(error);

    createToast("error", "Error al descargar", `No se pudo descargar el archivo ${file.fileName}.`, 5000);
    updateButtonLabel(buttonId, "<i class='fas fa-download'></i>", "btn-barymont-red");
  }
}

async function createModalCsvForSepa(btnOpenModalId) {
  try {
    const modalId = "csv-for-sepa-modal";
    const modalTableContentId = "csv-files-table";
    const modalTableContainerId = "csv-table-container";
    const title = "Descargar CSV para SEPA";

    const bodyContent = `<div id="${modalTableContainerId}" class="table-responsive scroll-barymont-red" style="max-height: 450px;">
                            <div id="table-of-csv-files-list-on-modal" class="text-center my-3"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" style="pointer-events: none;"></span>Cargando ficheros...</div>
                            <table id="${modalTableContentId}" class="table table-striped table-bordered table-hover table-sm w-100 text-center mb-0 d-none">
                              <thead class="thead-light">
                                <tr>
                                  <th>Nombre</th>
                                  <th>Última Modificación</th>
                                  <th>Descargar</th>
                                </tr>
                              </thead>
                              <tbody></tbody>
                            </table>
                          </div>
                          <div class="text-center">
                            <button id="btn-load-more-csv" class="btn btn-barymont-black w-100 d-none">Cargar más ficheros</button>
                          </div>`;

    const footerButtons = [
      {
        id: "btn-close-csv-for-sepa-modal",
        label: "Cerrar",
        classes: "btn btn-barymont-black",
        attr: "data-dismiss='modal'",
      },
    ];
    const modalBodyClasses = "text-center p-0";

    const modal = createModal(modalId, "lg", title, bodyContent, footerButtons, modalBodyClasses);

    $(modal).modal("show");

    CSV_FILES_FROM_REQUEST = [];

    const initialTableLoader = document.getElementById("table-of-csv-files-list-on-modal");
    const table = document.getElementById(modalTableContentId);
    const loadMoreButton = document.getElementById("btn-load-more-csv");

    await renderMoreFiles(modalTableContentId);
    initialTableLoader.classList.add("d-none");
    table.classList.remove("d-none");

    loadMoreButton.addEventListener("click", async () => {
      showLoadingButton("btn-load-more-csv", "btn-barymont-black");
      await renderMoreFiles(modalTableContentId);
    });

    updateButtonLabel(btnOpenModalId, "<i class='fas fa-file text-dark mr-2'></i> Ver ficheros CSV para SEPA", "btn-barymont-dark-grey");
  } catch (error) {
    console.error("Error al crear el modal:", error);
    createToast("error", "Error al cargar", "No se pudieron cargar los archivos CSV para SEPA.", 5000);
  }
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/herramientas/liquidaciones") {
      const btnOpenModalId = "btn-open-csv-for-sepa-modal";

      document.getElementById(btnOpenModalId)?.addEventListener("click", async () => {
        showLoadingButton(btnOpenModalId, "btn-barymont-dark-grey");

        await createModalCsvForSepa(btnOpenModalId);
      });
    }
  });
}

export default {};
